// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getPointRecordsCollections, postPointRecordsOperation } from '@/services/members.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      console.log(this.$i18n.locale)
      this.collapseLength = [...new Array(this.formData.length).keys()]
      if (this.$route.params.name === 'add') {
        this.formData[0].createDateItem[0].value = this.$i18n.locale === 'en' ? 'Manual Top Up' : '人工增加'
        this.formData[0].createDateItem[3].label = this.$t('form.members.topUpBy')
        this.formData[0].createDateItem[3].Object.rules[0].message = this.$t('text.require', { name: this.$t('form.members.topUpBy') })
        // this.formData[0].createDateItem[4].prop = 'top_up_amount'
        // this.formData[0].createDateItem[4].label = this.$t('form.members.topUpAmount')
        // this.formData[0].createDateItem[4].Object.rules[0].message = this.$t('text.require', { name: this.$t('form.members.topUpAmount') })
      } else if (this.$route.params.name === 'sub') {
        this.formData[0].createDateItem[0].value = this.$i18n.locale === 'en' ? 'Manual Deduct' : '人工扣除'
        this.formData[0].createDateItem[3].label = this.$t('form.members.deductBy')
        this.formData[0].createDateItem[3].Object.rules[0].message = this.$t('text.require', { name: this.$t('form.members.deductBy') })
        // this.formData[0].createDateItem[4].prop = 'deduct_amount'
        // this.formData[0].createDateItem[4].label = this.$t('form.members.deductAmount')
        // this.formData[0].createDateItem[4].Object.rules[0].message = this.$t('text.require', { name: this.$t('form.members.deductAmount') })
      }
      this.$store.state.app.loading = this.$loading(loading)
      getPointRecordsCollections().then(res => {
        if (res.status === 200) {
          if (this.$route.params.name === 'add') {
            this.formData[0].createDateItem[3].options = res.data.collections.top_up_by_reward_points
          } else if (this.$route.params.name === 'sub') {
            this.formData[0].createDateItem[3].options = res.data.collections.top_up_by_deduct_reward_points
          }
          this.formData[0].createDateItem[0].options = res.data.collections.operation_types
          // this.formData[0].createDateItem[3].options = []
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleValidateBefore (data) {
      // if (data.user_id.trim() !== '' || data.phone_number.trim() !== '') {
      //   delete this.formData[0].createDateItem[1].Object.rules
      //   delete this.formData[0].createDateItem[2].Object.rules
      // } else {
      //   this.formData[0].createDateItem[1].Object.rules = [
      //     { required: true, message: this.$t('form.members.allIn') }
      //   ]
      //   this.formData[0].createDateItem[2].Object.rules = [
      //     { required: true, message: this.$t('form.members.allIn') }
      //   ]
      // }
    },
    handleChange (value, prop, item) {
      if (item.prop === 'user_id' && value !== '') {
        this.formData[0].createDateItem[2].Object.rules[0].required = false
      } else if (item.prop === 'user_id' && value === '') {
        this.formData[0].createDateItem[2].Object.rules[0].required = true
      }
      if (item.prop === 'phone_number' && value !== '') {
        this.formData[0].createDateItem[1].Object.rules[0].required = false
      } else if (item.prop === 'phone_number' && value === '') {
        this.formData[0].createDateItem[1].Object.rules[0].required = true
      }
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      const params = {
        point_operation: obj
      }
      if (this.$route.params.name === 'add') {
        params.point_operation.top_up_by = params.point_operation.methods
        delete params.point_operation.methods
      } else if (this.$route.params.name === 'sub') {
        params.point_operation.deduct_by = params.point_operation.methods
        delete params.point_operation.methods
      }
      Object.keys(params.point_operation).forEach(res => {
        if (typeof params.point_operation[res] === 'boolean') {
          if (params.point_operation[res] === true) params.point_operation[res] = '1'
          if (params.point_operation[res] === false) params.point_operation[res] = '0'
        }
      })
      postPointRecordsOperation(params).then(res => {
        if (res.status === 200 || res.status === 204) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'pointsRecordList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
