import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'

export default {
  formData: [{
    title: i18n.t('form.members.pointOperation'),
    name: 0,
    createDateItem: [{
      span: 8,
      prop: 'operation_type',
      name: 'select',
      options: [],
      value: '人工增加',
      label: i18n.t('form.members.pointOperationType'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        disabled: true,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.members.pointOperationType') }) }
        ]
      }
    }, {
      span: 8,
      prop: 'user_id',
      name: 'input',
      label: i18n.t('form.members.userId'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        nextChange: true,
        rules: [
          { required: true, message: i18n.t('form.members.allIn') }
        ]
      }
    }, {
      span: 8,
      prop: 'phone_number',
      name: 'input',
      label: i18n.t('form.members.phoneNumber'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        nextChange: true,
        rules: [
          { required: true, message: i18n.t('form.members.allIn') }
        ]
      }
    }, {
      span: 8,
      prop: 'methods',
      name: 'select',
      options: [],
      value: '',
      label: i18n.t('form.members.topUpBy'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.members.topUpBy') }) }
        ]
      }
    }, {
      span: 8,
      prop: 'top_up_amount',
      name: 'input',
      label: i18n.t('form.members.topUpAmount'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'methods',
        visibleValue: 'reward_point',
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.members.topUpAmount') }) }
        ]
      }
    }, {
      span: 8,
      prop: 'deduct_amount',
      name: 'input',
      label: i18n.t('form.members.deductAmount'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'methods',
        visibleValue: 'deduct_reward_point',
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.members.deductAmount') }) }
        ]
      }
    }, {
      span: 8,
      prop: 'amount_paid',
      name: 'input',
      label: i18n.t('form.members.amountPaid'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'methods',
        visibleValue: 'calculate_by_amount_paid',
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.members.amountPaid') }) }
        ]
      }
    }, {
      span: 8,
      prop: 'remarks',
      name: 'input',
      label: i18n.t('form.members.remarks'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }]
  }]
}
